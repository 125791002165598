<template>
  <v-card rounded="lg" flat max-width="500" class="px-md-12 py-md-10 pa-4 width100" min-height="448">
    <div @click="toLogin" class="link primary--text text--lighten-1 mb-7">
      <v-icon color="primary lighten-1">mdi-chevron-left</v-icon> Back to Log In
    </div>
    <div class="f32 mb-2">Request already used</div>
    <div class="text--text">The password reset request is already used.</div>
    <v-img class="mt-2" contain height="150" src="@/assets/img/wait.svg"></v-img>
  </v-card>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  methods: {
    toLogin() {
      this.$router.push('/login');
    },
  },
};
</script>
